@font-face {
  font-family: icon;
  src: url(/assets/fonts/icon.eot?rvx1et);
  src: url(/assets/fonts/icon.eot?rvx1et#iefix) format("embedded-opentype"),
    url(/assets/fonts/icon.ttf?rvx1et) format("truetype"),
    url(/assets/fonts/icon.woff?rvx1et) format("woff"),
    url(/assets/fonts/icon.svg?rvx1et#icon) format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

.icon {
  font-family: icon !important;
  speak: never;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}

.icon-menu:before {
  content: "\e90c";
}
.icon-menu-close:before {
  content: "\e90a";
}
.icon-facebook:before {
  content: "\e906";
}
.icon-line:before {
  content: "\e909";
}
.icon-mail-2:before {
  content: "\e911";
}
.icon-phone-2:before {
  content: "\e90e";
}
.icon-search:before {
  content: "\e90d";
}
.icon-filter:before {
  content: "\e91a";
}
.icon-star:before {
  content: "\e919";
}
.icon-calendar1:before {
  content: "\e904"
}
.icon-arr-down:before {
  content: '\e900';
}
