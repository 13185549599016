// ===========================================================================================
//   @ min-screen(width)                      // shortcut for @media screen and (min-width ...)
//   @ max-screen(width)                      // shortcut for @media screen and (max-width ...)
@mixin max-screen($res) {
  @media screen and (max-width: $res) {
    @content;
  }
}

@mixin min-screen($res) {
  @media screen and (min-width: $res) {
    @content;
  }
}

// Text ellipsis
@mixin text-ellipsis($line) {
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
