@import './variable';
@import './mixin';

.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.d-none {
  display: none;
}

.d-sm-none {
  @include min-screen($bp-sm) {
    display: none !important;
  }
}

.d-md-none {
  @include min-screen($bp-md) {
    display: none;
  }
}

.d-md-flex {
  @include min-screen($bp-md) {
    display: flex;
  }
}

.d-sm-block {
  @include min-screen($bp-sm) {
    display: block;
  }
}

.d-md-block {
  @include min-screen($bp-md) {
    display: block;
  }
}
