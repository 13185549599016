@import "./variable";
@import "./mixin";

:root {
  --tw-white: #fff;
  --tw-black: #000;
  --tw-gray: #6d7278;
  --tw-light-gray: #eceef3;
  --tw-dark-blue: #0089df;
  --tw-blue: #019dff;
  --tw-sky-blue: #eef9ff;
  --tw-green: #00bf80;
  --tw-yellow: #ffbc05;
  --tw-red: #ff0202;
}

a {
  color: var(--tw-blue);
  text-decoration: none;
}

.is-desktop {
  display: none;

  @include min-screen($bp-lg) {
    display: block;
  }
}

.is-mobile {
  display: block;

  @include min-screen($bp-lg) {
    display: none;
  }
}

.pointer {
  cursor: pointer;
}
